<template>
    <div style="background-color: white !important;" class="main-div-print">
        <meta v-if="$vuetify.breakpoint.smAndDown || is_print" name="viewport" content="width=device-width,initial-scale=0.6">
        <meta v-else name="viewport" content="width=device-width,initial-scale=1.0">
        <div v-if="loading" style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 88vh;">
            <div style="display: flex; flex-direction: column; align-items: center;">
                <div style="display: block;">
                    <v-img :src="logo" height="230" width="230" contain></v-img>
                </div>
                <div style="display: block; text-align: center; margin-top: 30px; width: 100%; ">
                    <v-progress-linear indeterminate color="primary" rounded class="mx-auto" style="width: 60%; height: 10px;"></v-progress-linear>
                    <p style="margin-top: 18px; font-size: 26pt !important;"> Please wait ... </p>
                    <p style="margin-top: 4px; font-size: 26pt !important; font-family: xc-noto-sans !important;"> بۆ بینینی ئەنجامەکان تکایە چاوەڕوانبە </p>
                    <p style="margin-top: 4px; font-size: 26pt !important; font-family: xc-noto-sans !important;"> للنتائج يرجى الانتظار </p>
                </div>
            </div>
            <div style="display: block; position: fixed; bottom: 15px;">
                <small> Copyright &copy;  {{ new Date().getFullYear() }} Xera Clinic </small>
            </div>
        </div>

        <div v-else-if="!lab_sample" style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 88vh;">
            <div style="display: flex; flex-direction: column; align-items: center;">
                <div style="display: block;">
                    <v-img :src="logo" height="230" width="230" contain></v-img>
                </div>
                <div style="display: block; text-align: center; margin-top: 30px; width: 100%;">
                    <p style="margin-top: 6px; font-size: 26pt !important;"> Sample not found </p>
                </div>
            </div>
            <div style="display: block; position: fixed; bottom: 15px;">
                <small> Copyright &copy;  {{ new Date().getFullYear() }} Xera Clinic </small>
            </div>
        </div>

        <div v-else-if="verifiedTests == 0" style="display: flex; justify-content: center; align-items: center;">
            <div style="display: flex; flex-direction: column; align-items: center;">
                <div style="display: block;">
                    <v-img :src="logo" height="230" width="230" contain></v-img>
                </div>
                <div style="display: block; text-align: center; margin-top: 30px; width: 100%;">
                    <p style="margin-top: 6px; font-size: 26pt !important;"> Please wait ... </p>
                    <p style="margin-top: 6px; font-size: 26pt !important;"> Sample result(s) is not completed </p>
                    <p style="margin-top: 4px; font-size: 26pt !important; font-family: xc-noto-sans !important;"> چاوەڕوانبە، ئەنجامەکان تەواو نەبوون </p>
                    <p style="margin-top: 4px; font-size: 26pt !important; font-family: xc-noto-sans !important;"> انتظر، النتائج لم تنته بعد </p>
                </div>
            </div>
            <div style="display: block; position: fixed; bottom: 15px;">
                <small> Copyright &copy;  {{ new Date().getFullYear() }} Xera Clinic </small>
            </div>
        </div>

        <v-container fluid class="px-0" :class="{'py-0': !is_print}" v-if="lab_sample" :style="`width: 100%; background-color: white !important; ${loading || !lab_sample || verifiedTests == 0 ? 'display: none;' : ''}`" >
            <table :style="`width:100%; background-color: white !important; border-spacing: 0;`">
            <thead>
                <tr>
                    <td>
                        <div class="header-space" id="header-space-div" :style="`height: ${header_height}cm;`">
                            <div :class="{'header': is_print}" style="color: #262626 !important; width: 100%;"> 
                                
                                <img v-if="$store.getters.lab.lab_invoice_header" @load="++loadedImages" id="header-image" :style="`width: 100.3% !important; max-width: 100.3%; height: 4cm !important;  ${$store.state.print_without_header_and_footer ? 'visibility: hidden !important;' : ''}`" :src="$image_url + 'lab/image/invoice_header/' + $store.getters.lab.lab_invoice_header" />
                                
                                <v-row v-if="$store.getters.lab_setting.patient_info_style_patient_report == 'normal_style'" id="patient-info" :style="`height: 2.339cm !important; margin-top: -8px !important;  background-color: #EBEBF0; align-items: center !important; `" class="mx-0" :class="{'mt-n1': $store.getters.lab.lab_invoice_header, 'mt-0': !$store.getters.lab.lab_invoice_header}">
                                    <v-col cols="5"  class="pt-2 text-left">
                                        <span :style="`font-size: ${$store.getters.lab_setting.sample_patient_info_font_size}pt; margin-left: 0px !important;`"><b>{{ 'Patient' }}:</b> <span :style="`${$store.getters.lab.sample_patient_name_background_color ? `background-color: ${$store.getters.lab.sample_patient_name_background_color}; ${$store.getters.lab_setting.patient_name_style == 'bold' ? 'font-weight: 600;' : ''} ${$store.getters.lab_setting.patient_name == 'capitalized' ? 'text-transform: capitalize;' : ''} padding-inline: 3px; border-radius: 4px; display: inline-block; page-break-inside: avoid; padding-block: 0;` : ''}  ${$store.getters.lab.sample_patient_name_text_color ? `color: ${$store.getters.lab.sample_patient_name_text_color} !important;` : ''}`"> {{ lab_sample.patient_full_name }} </span></span>
                                        <br>
                                        <span :style="`font-size: ${$store.getters.lab_setting.sample_patient_info_font_size}pt; margin-left: 0px !important;`"> <b>{{ 'Age' }}:</b> {{ patient_info.patient_birth_date ? `${getAge(patient_info.patient_birth_date).years} Y, ${getAge(patient_info.patient_birth_date).months} M, ${getAge(patient_info.patient_birth_date).days} D` : null }} </span>
                                        <br>
                                        <span :style="`font-size: ${$store.getters.lab_setting.sample_patient_info_font_size}pt; margin-left: 0px !important;`"> <b>{{ 'Gender' }}:</b> {{ patient_info.gender_name_en }} </span>
                                    </v-col>

                                    <v-col cols="4" class="pt-2">
                                        <span :style="`font-size: ${$store.getters.lab_setting.sample_patient_info_font_size}pt;`"> <b>{{ $store.getters.lab_setting.referral_type }}:</b> <span> {{ lab_sample.lab_doctor_share_name }} </span> </span> 
                                        <br>
                                        <span :style="`font-size: ${$store.getters.lab_setting.sample_patient_info_font_size}pt;`"> <b>{{ 'Sample Code' }}:</b> {{ lab_sample.lab_sample_code }} </span>
                                        <br>
                                        <span :style="`font-size: ${$store.getters.lab_setting.sample_patient_info_font_size}pt;`"> <b>{{ 'Date' }}:</b> {{ lab_sample.lab_sample_date ? moment(lab_sample.lab_sample_date).format('DD-MM-yyyy') : null }} </span>
                                    </v-col>

                                    <v-col cols="3" :style="` text-align: right;`"  class="pt-1 pe-5">
                                        <div class="box" style="display: inline-block;">
                                            <img src="" alt="" class="canvas-patient-id" id="barcode-patient-id" style="height: 0.85cm; margin-top: 1px !important; margin-right: -2px !important;">
                                            <div :style="`font-size: ${$store.getters.lab_setting.sample_patient_info_font_size}pt; margin-top: -6px; display: flex; width: 0; min-width: 100%; justify-content: center;`"> {{ patient_info.patient_id }} </div>
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row v-else :style="`height: 3.089cm !important; margin-top: -8px !important;  background-color: #EBEBF0; align-items: center !important; `" class="mx-0" :class="{'mt-n1': $store.getters.lab.lab_invoice_header, 'mt-0': !$store.getters.lab.lab_invoice_header}">
                                    <v-col cols="5"  class="py-0 text-left" style="border-inline-end: 1px solid rgba(143, 144, 106, 1); border-width: thin;">
                                        <span :style="`margin-top: -4.5px !important; ${$store.getters.lab_setting.patient_name_style == 'bold' ? 'font-weight: 600;' : ''} font-size: ${Number($store.getters.lab_setting.sample_patient_info_font_size) + 2}pt; `"><span :style="`${$store.getters.lab.sample_patient_name_background_color ? `background-color: ${$store.getters.lab.sample_patient_name_background_color}; padding-inline: 3px; border-radius: 4px; display: inline-block; page-break-inside: avoid; padding-block: 0;` : ''}  ${$store.getters.lab.sample_patient_name_text_color ? `color: ${$store.getters.lab.sample_patient_name_text_color} !important;` : ''}`"> {{ $store.getters.lab_setting.patient_name == 'capitalized' ? (lab_sample.patient_full_name || '').toUpperCase() : (lab_sample.patient_full_name) }} </span></span>
                                        <br>
                                        <span :style="`font-size: ${11}pt; `">Age / Gender: {{ patient_info.patient_birth_date ? `${getAge(patient_info.patient_birth_date).years} Y, ${getAge(patient_info.patient_birth_date).months} M, ${getAge(patient_info.patient_birth_date).days} D` : null }} / {{ patient_info.gender_name_en }} </span>
                                        <br>
                                        <span :style="`font-size: ${11}pt;`">{{ $store.getters.lab_setting.referral_type }}: <span> {{ lab_sample.lab_doctor_share_name }} </span> </span>
                                        <br>
                                        <span :style="`font-size: ${11}pt;`">{{ 'Sample Code' }}: {{ lab_sample.lab_sample_code }} </span>
                                    </v-col>

                                    <v-col cols="5" class="py-0" style="border-inline-end: 1px solid rgba(143, 144, 106, 1); border-width: thin;">
                                        <div>
                                            <img src="" alt="" @load="++number_of_loaded_qr_and_barcode" class="canvas-sample-code" id="barcode-patient-id" style="height: 0.55cm; width: 3.1cm; margin-top: 2.4px;">
                                            <span style="line-height: 1; position: relative; top: -6.2px; font-size: 9pt; margin-inline-start: 6px; margin-top: -10px;">Sample Code: {{ lab_sample.lab_sample_code }} </span>
                                        </div>
                                        <div :style="`margin-top: -3.6px; font-size: ${11}pt;`">Registered on: {{ lab_sample.lab_sample_time ? moment(Number(lab_sample.lab_sample_time)).format('DD-MM-YYYY \xa0 hh:mm A') : '' }}</div>
                                        <div :style="` font-size: ${11}pt;`">Confirmed on: {{ lab_sample.lab_sample_verified_time ? moment(Number(lab_sample.lab_sample_verified_time)).format('DD-MM-YYYY \xa0 hh:mm A') : '' }}</div>
                                        <div :style="` font-size: ${11}pt;`">Reported on: {{ moment().format('DD-MM-YYYY \xa0 hh:mm A') }}</div>
                                    </v-col>
    
                                    <v-col cols="2" class="py-0 text-center">
                                        <div class="box" style="display: inline-block; width: 100%; text-align: center;">
                                            <div  class="text-center" style="font-weight: 600; width: 0; min-width: 100%; font-size: 8.5pt; margin-top: -3px; margin-bottom: 0 !important; color: black;">
                                                {{ 'Scan to results' }}
                                            </div>
                                            <img @load="++number_of_loaded_qr_and_barcode" id="img_qr" style="width: 20mm; height: 20mm; margin-top: 2px;"/> 
                                        </div>
                                    </v-col>
                                </v-row>

                            </div>
                        </div>
                    </td>
                </tr>
            </thead>
            <tbody :style="`width: 100% !important; `"><tr :style="`width: 100% !important; background-color: white !important;`"><td :style="`width: 100% !important; background-color: white !important;`">
                <div class="content  px-0 mx-0" :style="`width: 100% !important;background-color: white !important;`">
                    
                    <table id="table" class="px-0" :style="`background-color: white !important; width: 100.5% !important; border-collapse: collapse;  `" >
                        <thead>
                            <tr v-if="$store.getters.lab_setting.test_title == 'top_of_content'">
                                <th :class="`th-header-title ${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; width: 34.1% !important; height: 0.608cm !important; padding-left: 12px;`"> Test </th>
                                <th :class="`th-header-title ${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; width: 17.15% !important; height: 0.608cm !important;`"> <span style="margin-left: 0 !important;"> Result </span> </th>
                                <th :class="`th-header-title ${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; width: 13.45% !important; height: 0.608cm !important;`"> Unit </th>
                                <th class="th-header-title text-left" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; ${['all_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'padding-inline-start: 6px;' : ''} width: 35.3% !important; height: 0.608cm !important;`"> Normal Range </th>
                            </tr>
                        </thead>
                        
                        <!-- Departments -->
                        <tbody :style="`background-color: white !important; ${$store.getters.lab_setting.print_group_tests == 'none_seperated' ? 'page-break-inside: avoid;' : ''}`" v-for="(dept, index) in unique_lab_test_departments_list.filter(obj => !lab_sample_tests.filter(s => !s.lab_test_sub_department_id && s.lab_test_department_id == dept && (!s.lab_sample_sub_tests || s.lab_sample_sub_tests.length == 0)).map(obj => obj.lab_test_department_id).includes(obj))" :key="'ddd' + index">
                            <tr v-if="$store.getters.lab_setting.test_group_separation == 'none_separated'" :style="`${$store.getters.lab_setting.test_title == 'under_test_group' ? getBorder('rows', true) : ''}  ${getBorder('rows')} border-right: 1px solid ${$store.getters.lab.print_group_background_color}; border-left: 1px solid ${$store.getters.lab.print_group_background_color}; border-raduis: 0 !important;`">
                                <td :colspan="$store.getters.lab_setting.test_group_width == 'all_width' ? 4 : 2" :style="`text-align: ${$store.getters.lab_setting.sample_group_text_alignment}; ${$store.getters.lab_setting.sample_group_text_alignment == 'center' ? 'padding-inline: 0 !important;' : 'padding-left: 12px;'} color: ${$store.getters.lab.print_group_text_color}; font-weight: 600; font-size: ${$store.getters.lab_setting.sample_group_font_size}pt !important; background-color: ${$store.getters.lab.print_group_background_color} !important; width: 100%; border-left: none; border-right: none; border-raduis: 0 !important;`">
                                    {{ lab_test_departments.find(obj => obj.lab_test_department_id == dept).lab_test_department_name }}
                                </td>
                                <template v-if="$store.getters.lab_setting.test_group_width == 'half_width'">
                                    <td></td>
                                    <td></td>
                                </template>
                            </tr>

                            <tr v-else :style="`${getBorder('rows')} border-right: none; border-left: none;`">
                                <td :colspan="$store.getters.lab_setting.test_group_width == 'all_width' ? 4 : 2">
                                    <div :style="`align-items: center; ${$store.getters.lab_setting.sample_group_text_alignment == 'center' ? 'justify-content: center;' : ''} ${$store.getters.lab_setting.sample_group_text_alignment == 'center' ? 'padding-inline: 0 !important;' : 'padding-left: 12px;'} color: ${$store.getters.lab.print_group_text_color}; font-weight: 600; font-size: ${$store.getters.lab_setting.sample_group_font_size}pt !important; background-color: ${$store.getters.lab.print_group_background_color} !important; display: flex; border-radius: 6px !important; margin-block: 8px; `">
                                        {{ lab_test_departments.find(obj => obj.lab_test_department_id == dept).lab_test_department_name }}
                                    </div>
                                </td>
                                <template v-if="$store.getters.lab_setting.test_group_width == 'half_width'">
                                    <td></td>
                                    <td></td>
                                </template>
                            </tr>

                            <tr v-if="$store.getters.lab_setting.test_title == 'under_test_group'">
                                <th :class="`th-header-title ${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; width: 34.1% !important; height: 0.608cm !important; padding-left: 12px;`"> Test </th>
                                <th :class="`th-header-title ${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; width: 17.15% !important; height: 0.608cm !important;`"> <span style="margin-left: 0 !important;"> Result </span> </th>
                                <th :class="`th-header-title ${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; width: 13.45% !important; height: 0.608cm !important;`"> Unit </th>
                                <th class="th-header-title text-left" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; ${['all_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'padding-inline-start: 6px;' : ''} width: 35.3% !important; height: 0.608cm !important;`"> Normal Range </th>
                            </tr>

                            <template v-for="item in lab_sample_tests.filter(obj => !obj.lab_test_sub_department_id && obj.lab_test_department_id == dept && (!obj.lab_sample_sub_tests || obj.lab_sample_sub_tests.length == 0))">
                                <tr :style="`page-break-inside: avoid; ${$store.getters.lab_setting.show_sample_data_border == 'none' || !['all_column_row', 'all_row'].includes($store.getters.lab_setting.show_sample_data_border) || (item.lab_sample_test_result_analyse && item.lab_sample_test_result_analyse != '<p></p>') ? getBorder('none') : getBorder('rows')} ${hasChartWithTest(item.lab_test_id) ? 'border-bottom: none;' : ( ['all_row', 'all_column_row'].includes($store.getters.lab_setting.show_sample_data_border ? 'border-bottom: 1px solid #8F906A;' : '') )} background-color: white !important;`"  :key="'test'+item.lab_sample_test_id">
                                    <td :class="`${['all_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`vertical-align: top; overflow-wrap: anywhere; padding-right: 15px; padding-left: 12px; ${getBorder('rows')} ${hasChartWithTest(item.lab_test_id) ? 'border-bottom: none; border-right: none;' : ''} height: 0.608cm !important; font-size: ${$store.getters.lab_setting.sample_content_font_size}pt !important; color: ${item.lab_sample_test_color};`">
                                        {{ item.lab_test_name }}
                                        <div v-if="$store.getters.lab_setting.show_analyzer_sample_print == 'true'" style="font-size: 9pt; color: #7F7F7F; margin-top: -3px; margin-left: 0.5px;"> {{ item.lab_analyzer_name }} </div>
                                    </td>
                                    <td :class="`${['all_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`vertical-align: top; ${getBorder('rows')} ${hasChartWithTest(item.lab_test_id) ? 'border-bottom: none; border-right: none; border-left: none;' : ''} color: ${(calculate_test_result(item) || {color: '#262626'}).color}; height: 0.608cm !important; font-size: ${$store.getters.lab_setting.sample_content_font_size}pt !important;`"><span style="margin-left: 0 !important;">{{ item.lab_sample_test_result }}</span></td>
                                    <td :class="`${['all_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`vertical-align: top; ${getBorder('rows')} ${hasChartWithTest(item.lab_test_id) ? 'border-bottom: none; border-right: none; border-left: none;' : ''} height: 0.608cm !important; font-size: ${$store.getters.lab_setting.sample_content_font_size}pt !important;`"> {{ item.lab_test_unit_name }}  </td>
                                    <td class="text-start" :style="`white-space: pre-wrap; max-width: 7.436cm; vertical-align: top; ${getBorder('rows')} ${hasChartWithTest(item.lab_test_id) ? 'border-bottom: none; border-left: none;' : ''} font-size: ${$store.getters.lab_setting.sample_content_font_size}pt !important; ${['all_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'padding-inline-start: 6px;' : ''}`">{{ item.lab_test_normal_range_display_type == 'general_range' ? item.lab_sample_test_normal_range_note : calculate_test_result(item).normal_range_note}}</td>
                                </tr>
                                <tr :key="'note'+item.lab_sample_test_id" v-if="item.lab_sample_test_result_analyse && item.lab_sample_test_result_analyse != '<p></p>'">
                                    <td colspan="4"  class="htmlv" :style="`white-space: pre-wrap !important; padding-right: 8px; padding-left: 12px; page-break-inside: avoid; ${['chart_with_test', 'chart_with_test_no_title'].includes($store.state.lab_setting.show_charts_type_sample_print) && options.find(o => o.chart_id == item.lab_test_id) || ['none', 'profiles_only_column_row', 'profiles_only_row'].includes($store.getters.lab_setting.show_sample_data_border) ? `${getBorder('rows')} border-bottom: none !important;` : getBorder('rows')} ${item.lab_sample_test_result_analyse && item.lab_sample_test_result_analyse != '<p></p>' && hasChartWithTest(item.lab_test_id) ? 'border-bottom: none; border-top: none;' : ''} font-size: ${$store.getters.lab_setting.sample_content_font_size}pt; background-color: white !important;`" v-html="item.lab_sample_test_result_analyse"></td>
                                </tr>
                                <tr v-if="$store.state.display_chart_in_print_sample && hasChartWithTest(item.lab_test_id)" :style="`${getBorder('rows')} border-top: none; background-color: white !important;`"  :key="'chart'+item.lab_test_id + Math.random()">
                                    <td colspan="4" :style="`vertical-align: top; font-family: Calibri !important; font-size: 12pt !important; ${getBorder('rows')} border-top: none; border-width: thin;`">
                                        <div :id="'chart' + item.lab_test_id" style="display: flex; justify-content: center !important; break-inside: avoid; width: 100% !important;">
                                            
                                        </div>
                                    </td>
                                </tr>

                            </template>
                            
                        </tbody>

                        <!-- Sub Departments -->
                        <tbody :style="`background-color: white !important; ${$store.getters.lab_setting.print_group_tests == 'none_seperated' ? 'page-break-inside: avoid;' : ''}`" v-for="(sub_dept, index) in unique_lab_test_sub_departments_list" :key="'s' + index">
                            <tr v-if="$store.getters.lab_setting.test_group_separation == 'none_separated'" :style="`${$store.getters.lab_setting.test_title == 'under_test_group' ? getBorder('rows', true) : ''}  ${getBorder('rows')} border-right: 1px solid ${$store.getters.lab.print_group_background_color}; border-left: 1px solid ${$store.getters.lab.print_group_background_color}; border-raduis: 0 !important;`">
                                <td :colspan="$store.getters.lab_setting.test_group_width == 'all_width' ? 4 : 2" :style="`text-align: ${$store.getters.lab_setting.sample_group_text_alignment}; ${$store.getters.lab_setting.sample_group_text_alignment == 'center' ? 'padding-inline: 0 !important;' : 'padding-left: 12px;'} color: ${$store.getters.lab.print_group_text_color}; font-weight: 600; font-size: ${$store.getters.lab_setting.sample_group_font_size}pt !important; background-color: ${$store.getters.lab.print_group_background_color} !important; width: 100%; border-left: none; border-right: none; border-raduis: 0 !important;`">
                                    {{ lab_test_sub_departments.find(obj => obj.lab_test_sub_department_id == sub_dept).lab_test_sub_department_name }}
                                </td>
                                <template v-if="$store.getters.lab_setting.test_group_width == 'half_width'">
                                    <td></td>
                                    <td></td>
                                </template>
                            </tr>

                            <tr v-else :style="`${getBorder('rows')} border-right: none; border-left: none;`">
                                <td :colspan="$store.getters.lab_setting.test_group_width == 'all_width' ? 4 : 2">
                                    <div :style="`align-items: center; ${$store.getters.lab_setting.sample_group_text_alignment == 'center' ? 'justify-content: center;' : ''} ${$store.getters.lab_setting.sample_group_text_alignment == 'center' ? 'padding-inline: 0 !important;' : 'padding-left: 12px;'} color: ${$store.getters.lab.print_group_text_color}; font-weight: 600; font-size: ${$store.getters.lab_setting.sample_group_font_size}pt !important; background-color: ${$store.getters.lab.print_group_background_color} !important; display: flex; border-radius: 6px !important; margin-block: 8px; `">
                                        {{ lab_test_sub_departments.find(obj => obj.lab_test_sub_department_id == sub_dept).lab_test_sub_department_name }}
                                    </div>
                                </td>
                                <template v-if="$store.getters.lab_setting.test_group_width == 'half_width'">
                                    <td></td>
                                    <td></td>
                                </template>
                            </tr>

                            <tr v-if="$store.getters.lab_setting.test_title == 'under_test_group'">
                                <th :class="`th-header-title ${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; width: 34.1% !important; height: 0.608cm !important; padding-left: 12px;`"> Test </th>
                                <th :class="`th-header-title ${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; width: 17.15% !important; height: 0.608cm !important;`"> <span style="margin-left: 0 !important;"> Result </span> </th>
                                <th :class="`th-header-title ${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; width: 13.45% !important; height: 0.608cm !important;`"> Unit </th>
                                <th class="th-header-title text-left" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; ${['all_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'padding-inline-start: 6px;' : ''} width: 35.3% !important; height: 0.608cm !important;`"> Normal Range </th>
                            </tr>

                            <template v-for="item in lab_sample_tests.filter(obj => obj.lab_test_sub_department_id == sub_dept && (!obj.lab_sample_sub_tests || obj.lab_sample_sub_tests.length == 0))">
                                <tr :style="`${$store.getters.lab_setting.show_sample_data_border == 'none' || !['all_column_row', 'all_row'].includes($store.getters.lab_setting.show_sample_data_border) || (item.lab_sample_test_result_analyse && item.lab_sample_test_result_analyse != '<p></p>') ? getBorder('none') : getBorder('rows')} ${hasChartWithTest(item.lab_test_id) ? 'border-bottom: none;' : ( ['all_row', 'all_column_row'].includes($store.getters.lab_setting.show_sample_data_border ? 'border-bottom: 1px solid #8F906A;' : '') )} background-color: white !important; page-break-inside: avoid;`" :key="'test' + item.lab_sample_test_id">
                                    <td :class="`${['all_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`vertical-align: top; overflow-wrap: anywhere; padding-right: 15px; padding-left: 12px; ${getBorder('rows')} ${hasChartWithTest(item.lab_test_id) ? 'border-bottom: none; border-right: none;' : ''} height: 0.608cm !important; font-size: ${$store.getters.lab_setting.sample_content_font_size}pt !important; color: ${item.lab_sample_test_color};`">
                                        {{ item.lab_test_name }}
                                        <div v-if="$store.getters.lab_setting.show_analyzer_sample_print == 'true'" style="font-size: 9pt; color: #7F7F7F; margin-top: -3px; margin-left: 0.5px;"> {{ item.lab_analyzer_name }} </div>
                                    </td>
                                    <td :class="`${['all_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`vertical-align: top;  ${getBorder('rows')} ${hasChartWithTest(item.lab_test_id) ? 'border-bottom: none; border-right: none; border-left: none;' : ''} color: ${(calculate_test_result(item) || {color: '#262626'}).color}; height: 0.608cm !important; font-size: ${$store.getters.lab_setting.sample_content_font_size}pt !important;`"> <span style="margin-left: 0 !important;"> {{ item.lab_sample_test_result }} </span> </td>
                                    <td :class="`${['all_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`vertical-align: top; ${getBorder('rows')} ${hasChartWithTest(item.lab_test_id) ? 'border-bottom: none; border-right: none; border-left: none;' : ''} height: 0.608cm !important; font-size: ${$store.getters.lab_setting.sample_content_font_size}pt !important;`"> {{ item.lab_test_unit_name }}  </td>
                                    <td class="text-start" :style="`white-space: pre-wrap; max-width: 7.436cm; vertical-align: top; ${getBorder('rows')} ${hasChartWithTest(item.lab_test_id) ? 'border-bottom: none; border-left: none;' : ''} font-size: ${$store.getters.lab_setting.sample_content_font_size}pt !important; ${['all_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'padding-inline-start: 6px;' : ''}`">{{ item.lab_test_normal_range_display_type == 'general_range' ? item.lab_sample_test_normal_range_note : calculate_test_result(item).normal_range_note }}</td>
                                </tr>
                                <tr :key="'note'+item.lab_sample_test_id" v-if="item.lab_sample_test_result_analyse && item.lab_sample_test_result_analyse != '<p></p>'">
                                    <td colspan="4"  class="htmlv" :style="`white-space: pre-wrap !important; padding-right: 8px; padding-left: 12px; page-break-inside: avoid; ${['chart_with_test', 'chart_with_test_no_title'].includes($store.state.lab_setting.show_charts_type_sample_print) && options.find(o => o.chart_id == item.lab_test_id) || ['none', 'profiles_only_column_row', 'profiles_only_row'].includes($store.getters.lab_setting.show_sample_data_border) ? `${getBorder('rows')} border-bottom: none !important;` : getBorder('rows')} ${item.lab_sample_test_result_analyse && item.lab_sample_test_result_analyse != '<p></p>' && hasChartWithTest(item.lab_test_id) ? 'border-bottom: none; border-top: none;' : ''} font-size: ${$store.getters.lab_setting.sample_content_font_size}pt; background-color: white !important;`" v-html="item.lab_sample_test_result_analyse"></td>
                                </tr>
                                <tr v-if="$store.state.display_chart_in_print_sample && hasChartWithTest(item.lab_test_id)" :style="`${getBorder('rows')} border-top: none; background-color: white !important;`"  :key="'chart'+item.lab_test_id + Math.random()">
                                    <td colspan="4" :style="`vertical-align: top; font-family: Calibri !important; font-size: 12pt !important; ${getBorder('rows')} border-top: none; border-width: thin;`">
                                        <div :id="'chart' + item.lab_test_id" style="display: flex; justify-content: center !important; break-inside: avoid; width: 100% !important;">
                                            
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            
                        </tbody>


                        <!-- Profiles -->
                        <tbody :style="`background-color: white !important; ${$store.state.lab_setting.print_profile_state == 'new_page' ? 'page-break-before: always;' : ''}`" v-for="(item, index) in lab_sample_tests.filter(obj => obj.lab_sample_sub_tests && obj.lab_sample_sub_tests.length > 0 && obj.lab_sample_sub_tests.filter(s => s.lab_sample_sub_test_result || (s.lab_sample_sub_test_note && s.lab_sample_sub_test_note != '<p></p>')).length > 0)" :key="index">
                            <tr v-if="$store.getters.lab_setting.test_group_separation == 'none_separated'" :style="`${$store.getters.lab_setting.test_title == 'under_test_group' ? getBorder('rows', true) : ''} border-right: 1px solid ${$store.getters.lab.print_group_background_color}; border-left: 1px solid ${$store.getters.lab.print_group_background_color}; border-bottom: ${$store.getters.lab_setting.show_sample_data_border == 'none' || (item.lab_sample_test_result_analyse && item.lab_sample_test_result_analyse != '<p></p>') ? 'none': '1px solid #8F906A'} !important; border-raduis: 0 !important;`">
                                <td :colspan="$store.getters.lab_setting.test_group_width == 'all_width' ? 4 : 2" :style="`text-align: ${$store.getters.lab_setting.sample_group_text_alignment}; ${$store.getters.lab_setting.sample_group_text_alignment == 'center' ? 'padding-inline: 0 !important;' : 'padding-left: 12px;'} font-size: ${$store.getters.lab_setting.sample_group_font_size}pt !important; background-color: ${$store.getters.lab.print_group_background_color} !important; color: ${$store.getters.lab.print_group_text_color}; font-weight: 600; width: 100%; border-left: none; border-right: none;`">
                                    {{ item.lab_test_name }}
                                </td>
                                <template v-if="$store.getters.lab_setting.test_group_width == 'half_width'">
                                    <td></td>
                                    <td></td>
                                </template>
                            </tr>

                            <tr v-else :style="`${getBorder('profiles')} border-right: none; border-left: none;`">
                                <td :colspan="$store.getters.lab_setting.test_group_width == 'all_width' ? 4 : 2">
                                    <div :style="`align-items: center; ${$store.getters.lab_setting.sample_group_text_alignment == 'center' ? 'justify-content: center;' : ''} ${$store.getters.lab_setting.sample_group_text_alignment == 'center' ? 'padding-inline: 0 !important;' : 'padding-left: 12px;'} color: ${$store.getters.lab.print_group_text_color}; font-weight: 600; font-size: ${$store.getters.lab_setting.sample_group_font_size}pt !important; background-color: ${$store.getters.lab.print_group_background_color} !important; display: flex; border-radius: 6px !important; margin-block: 8px; `">
                                        {{ item.lab_test_name }}
                                    </div>
                                </td>
                                <template v-if="$store.getters.lab_setting.test_group_width == 'half_width'">
                                    <td></td>
                                    <td></td>
                                </template>
                            </tr>

                            <tr v-if="$store.getters.lab_setting.test_title == 'under_test_group'">
                                <th :class="`th-header-title ${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; width: 34.1% !important; height: 0.608cm !important; padding-left: 12px;`"> Test </th>
                                <th :class="`th-header-title ${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; width: 17.15% !important; height: 0.608cm !important;`"> <span style="margin-left: 0 !important;"> Result </span> </th>
                                <th :class="`th-header-title ${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; width: 13.45% !important; height: 0.608cm !important;`"> Unit </th>
                                <th class="th-header-title text-left" :style="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border: 1px solid #8F906A !important;' : `border: 1px solid ${$store.getters.lab.print_title_background_color} !important;`} background-color: ${$store.getters.lab.print_title_background_color} !important; font-size: ${$store.getters.lab_setting.sample_title_font_size}pt !important; color: ${$store.getters.lab.print_title_text_color} !important; ${['all_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'padding-inline-start: 6px;' : ''} width: 35.3% !important; height: 0.608cm !important;`"> Normal Range </th>
                            </tr>

                            <template v-for="sub_test in sortSubTests(item.lab_sample_sub_tests.filter(obj => obj.lab_sample_sub_test_result || (obj.lab_sample_sub_test_note && obj.lab_sample_sub_test_note != '<p></p>')))">
                                <tr :style="`border-bottom: ${(!sub_test.lab_sample_sub_test_note || sub_test.lab_sample_sub_test_note == '<p></p>') && ($store.getters.lab_setting.show_sample_data_border == 'all_column_row' || $store.getters.lab_setting.show_sample_data_border == 'all_row' || $store.getters.lab_setting.show_sample_data_border == 'profiles_only_row') ? '1px solid #8F906A': 'none'} !important; ${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'border-right: 1px solid #8F906A; border-left: 1px solid #8F906A;' : ''} background-color: white !important;`"  :key="'sub-test-content' + sub_test.lab_sample_sub_test_id">
                                    <td :class="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`vertical-align: top; overflow-wrap: anywhere; padding-right: 15px; padding-left: 12px; ${getBorder('profiles')} color: ${sub_test.lab_sample_sub_test_color}; font-size: ${$store.getters.lab_setting.sample_content_font_size}pt !important;`"> {{ sub_test.lab_sub_test_name }} </td>
                                    
                                    <template v-if="!sub_test.lab_test_unit_name && !sub_test.lab_sample_sub_test_reference_range_note">
                                        <td colspan="3" :class="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`vertical-align: top; white-space: pre-wrap; width: 100%; ${getBorder('profiles')} font-size: ${$store.getters.lab_setting.sample_content_font_size}pt !important; color: ${sub_test.lab_sample_sub_test_result_color || ''};`"><span style="margin-left: 0;">{{ sub_test.lab_sample_sub_test_result }} </span> </td>
                                    </template> 
                                    <template v-else>
                                        <td :class="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`vertical-align: top; ${getBorder('profiles')} font-size: ${$store.getters.lab_setting.sample_content_font_size}pt !important; color: ${sub_test.lab_sub_test_filter ? calculate_sub_test_result(sub_test).color : (sub_test.lab_sample_sub_test_result_color || '')} !important;`"><span style="margin-left: 0;">{{ sub_test.lab_sample_sub_test_result }} </span> </td>
                                        <td :class="`${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'text-center' : 'text-left'}`" :style="`vertical-align: top; ${getBorder('profiles')} height: 0.608cm !important; font-size: ${$store.getters.lab_setting.sample_content_font_size}pt !important;`"> {{ sub_test.lab_test_unit_name }}  </td>
                                        <td class="text-start" :style="`vertical-align: top; ${getBorder('profiles')} height: 0.608cm !important; font-size: ${$store.getters.lab_setting.sample_content_font_size}pt !important; ${['all_column_row', 'profiles_only_column_row'].includes($store.getters.lab_setting.show_sample_data_border) ? 'padding-inline-start: 6px !important;' : ''}`">
                                            {{ sub_test.lab_sub_test_normal_range_display_type == 'general_range' ? sub_test.lab_sub_test_reference_range_note : calculate_sub_test_result(sub_test).normal_range_note }}
                                        </td>
                                    </template>
                                </tr>
                                <tr :key="'sub-test-note' + sub_test.lab_sample_sub_test_id" v-if="sub_test.lab_sample_sub_test_note && sub_test.lab_sample_sub_test_note != '<p></p>'" :style="` background-color: white !important; border-width: thin;`">
                                    <td colspan="4" class="htmlv" :style="`${getBorder('profiles')} vertical-align: top; white-space: pre-wrap !important; font-family: Calibri !important;overflow-wrap: anywhere; padding-right: 6.6px; padding-left: 12px; border-left: none; border-right: none; font-size: ${$store.getters.lab_setting.sample_content_font_size}pt;`" v-html="sub_test.lab_sample_sub_test_note"></td>
                                </tr>
                            </template>
                            <tr :key="'note'+item.lab_sample_test_id" v-if="item.lab_sample_test_result_analyse && item.lab_sample_test_result_analyse != '<p></p>'">
                                <td colspan="4" class="htmlv" :style="`white-space: pre-wrap !important; padding-right: 8px; padding-left: 12px; page-break-inside: avoid; ${getBorder('profiles')} font-size: ${$store.getters.lab_setting.sample_content_font_size}pt; background-color: white !important;`" v-html="item.lab_sample_test_result_analyse"></td>
                            </tr>
                            <tr v-if="item.lab_sample_test_note" :style="`${getBorder('profiles')} background-color: white !important;`"> 
                                <td colspan="4" class="note" :style="`padding-left: 12px; white-space: pre-wrap; width: 100%; background-color: white !important; ${getBorder('profiles')} white-space: pre-wrap; font-size: 12.2pt !important; font-family: Calibri !important;`" v-html="`<p style='font-family: Calibri !important;'> <span style='color: #7053F7; font-weight: bold; margin-left: -3.5px; font-family: Calibri !important;'>Note: </span>` + item.lab_sample_test_note"></td>
                            </tr>
                        </tbody>

                        </table>
                    <v-divider></v-divider>
                    <v-container fluid style="background-color: white !important; margin-top: 1cm !important; page-break-inside: avoid; width: 101% !important;" v-if="lab_sample.lab_sample_note">
                        <v-row class="ps-1 pe-2">
                            <v-col style="color: #262626; background-color: #FFE6E6 !important; font-size: 12.2pt !important;" class="note">
                                <span style="font-weight: bold;"> Sample note: </span> 
                                <span style="white-space: pre-wrap;" v-html="lab_sample.lab_sample_note"></span>
                                
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container fluid v-if="$store.state.display_chart_in_print_sample && $store.state.lab_setting.show_charts_type_sample_print == 'chart_end_of_report'" style="background-color: white !important; margin-top: -0.01cm !important;  width: 101% !important;">
                        <v-row style="background-color: white !important; margin-left: -20px;">
                            <v-col class="px-0" style=" margin-top: 0.5cm !important;">
                                <template v-for="(option, index) in options">
                                    <div :id="'chart' + option.chart_id" style="display: flex; justify-content: center !important; break-inside: avoid; width: 100% !important;" :key="'ch' + index">
                                        
                                    </div>
                                </template>

                                
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-btn style=" position: fixed; bottom: 14px; right: 14px;" :ripple="false" x-large icon class="no-print" @click="print()"> <v-icon color="#ff0000" v-ripple="false" :size="$vuetify.breakpoint.smAndDown ? 52 : 54"> mdi-file-pdf-box</v-icon> </v-btn>
                </div>
            </td></tr></tbody>
                <tfoot >
                    <tr>
                        <td>
                            <div class="footer-space" :style="`height: ${footer_height}cm !important; background-color: white !important;`">
                                <!-- width Value before is 100% and no margin bottom -->
                                <div :class="{'footer': is_print}" :style="`direction: ltr; width: 100.3%;  background-color: white !important; height: ${footer_height}cm;`" >
                                    <div v-if="hasStamp() && !$store.state.print_without_header_and_footer" style="width: 100%; display: flex;">
                                        <v-container fluid class="ma-0 pa-0">
                                            <v-layout row wrap class="pa-0 ma-0">
                                                <v-flex xs4 class="text-center ma-0 pa-0" style="max-height: 3cm !important;">
                                                    <img :src="$image_url + 'lab/image/sample_stamp/' + $store.getters.lab.lab_sample_stamp_start" @load="++loadedImages" :style="`${!$store.getters.lab.lab_sample_stamp_start ? 'visibility: hidden;' : ''} height: 3cm !important; max-width: 7cm; max-height: 3cm; object-fit: scale-down;`">
                                                </v-flex>
                                                <v-flex xs4 class="text-center ma-0 pa-0" style="max-height: 3cm !important;">
                                                    <img :src="$image_url + 'lab/image/sample_stamp/' + $store.getters.lab.lab_sample_stamp" @load="++loadedImages" :style="`${!$store.getters.lab.lab_sample_stamp ? 'visibility: hidden;' : ''} height: 3cm !important; max-width: 7cm; max-height: 3cm; object-fit: scale-down;`">
                                                </v-flex>
                                                <v-flex xs4 class="text-center ma-0 pa-0" style="max-height: 3cm !important;">
                                                    <img :src="$image_url + 'lab/image/sample_stamp/' + $store.getters.lab.lab_sample_stamp_end" @load="++loadedImages" :style="`${!$store.getters.lab.lab_sample_stamp_end ? 'visibility: hidden;' : ''} height: 3cm !important; max-width: 7cm; max-height: 3cm; object-fit: scale-down;`">
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </div>
                                    
                                    <!-- width Value before 100% -->
                                    <div class="text-center" :style="`width: 100.3% !important; height: 0.661cm !important; align-items: center; font-size: ${$store.getters.lab_setting.sample_verification_font_size}pt !important; color: #262626 !important;`">
                                        <v-icon class="me-1 mt-n1" color="#8F90A6" size="19"> mdi-check-decagram </v-icon>
                                        {{ $store.getters.lab_setting.sample_verification_text }}: &nbsp; {{ userVerified.join(' , ') }} &nbsp;&nbsp; on 
                                        <template v-if="maxVerified">
                                            {{ moment(maxVerified).format('DD-MM-YYYY') }} &nbsp;&nbsp;&nbsp;at {{ new Date(maxVerified).toLocaleTimeString() }}
                                        </template>
                                    </div>
                                    <!-- width Value before is 100%, margin bottom is -7.65 -->
                                    <img  v-if="$store.getters.lab.lab_invoice_footer" @load="++loadedImages" :src="$image_url + 'lab/image/invoice_footer/' + $store.getters.lab.lab_invoice_footer" :style="`bottom: 0 !important; width: 100.3%; height: 2.2cm !important; min-height: 2.2cm !important; max-height: 2.2cm !important;  ${$store.state.print_without_header_and_footer ? 'visibility: hidden !important;' : ''}`">
                                    
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
            
            
            
            </v-container>
    </div>
</template>


<script>
import moment from 'moment'
import VueBarcode from 'vue-barcode';
import ApexCharts from 'apexcharts'
import normal_range_request from '../../requests/lab_sample_test_normal_ranges.request'
import bwipjs from 'bwip-js'
import axios from 'axios';
import QRCode from 'qrcode'
export default {
    props: ['sample_id'],
    components: {
        barcode: VueBarcode
    },
    data() {
        return {
            logo: require('../../assets/main-logo.svg'),
            id: null,
            patient_info: {},
            lab_sample: {},
            lab_sample_tests: [],
            normal_ranges: [],
            sub_test_normal_ranges: [],
            maxVerified: null,
            options: [],
            header_img_height: null,
            footer_img_height: null,
            sample_stamp_height: null,
            patient_info_height: null,
            header_div_height: null,
            moment,
            loadedImages: 0,
            lab_test_departments: [],
            lab_test_sub_departments: [],
            is_print: false,
            loading: true,
            verifiedTests: 0
        }
    },
    mounted() {
        this.id = this.$route.params.uuid;
        setTimeout(() => {

            this.getData();
        }, 2000)

        window.matchMedia('print').addListener(e => {
            if(e.matches) {
                this.is_print = true
            } else {
                this.is_print = false
            }
        })
        
    },
    computed: {
        unique_lab_test_sub_departments_list() {
            return Array.from(new Set(this.lab_sample_tests.filter(obj => obj.lab_test_sub_department_id && (!obj.lab_sample_sub_tests || obj.lab_sample_sub_tests.length == 0)).map(obj => {
                return obj.lab_test_sub_department_id
            })))
        },
        unique_lab_test_departments_list() {
            return Array.from(new Set(this.lab_sample_tests.filter(obj => obj.lab_test_department_id && !obj.lab_test_sub_department_id && (!obj.lab_sample_sub_tests || obj.lab_sample_sub_tests.length == 0)).map(obj => {
                return obj.lab_test_department_id
            })))
        },
        userVerified() {
            return Array.from(new Set(this.lab_sample_tests.map(obj => obj.user_name_verified)))
        },
        current_width() {
            return document.body.clientWidth
        },
        header_height() {
            var height = 2;
            if(this.$store.getters.lab_setting.patient_info_style_patient_report == 'modern_style') {
                height += 0.75
            }
            if(this.$store.getters.lab.lab_invoice_header) {
                height += 4.203
            } 
            return height
        },
        footer_height() {
            var height = 0;
            if(this.$store.getters.lab.lab_invoice_footer) {
                height += 2.1
            }
            if(this.$store.getters.lab.lab_sample_stamp || this.$store.getters.lab.lab_sample_stamp_start || this.$store.getters.lab.lab_sample_stamp_end) {
                height += 3.73
            } else {
                height += 0.7
            }
            return height;
        },
        numberOfImages() {
            return [
                this.$store.getters.lab.lab_invoice_header,
                this.$store.getters.lab.lab_invoice_footer,
                this.$store.getters.lab.lab_sample_stamp,
                this.$store.getters.lab.lab_sample_stamp_start,
                this.$store.getters.lab.lab_sample_stamp_end,
            ].filter(Boolean).length
        }
       
    },
    methods: {
        async getData() {
            axios.get(`lab_samples/searchSampleUUID/${this.id}`).then(async r => {
                this.patient_info = r.data.patient_info;
                this.lab_sample = r.data.lab_sample;
                this.loading = false
                if(!this.lab_sample) {
                    return
                }
                this.lab_sample_tests = r.data.lab_sample_tests.filter(obj => obj.lab_sample_test_verified == 'true');
                this.verifiedTests = this.lab_sample_tests.filter(obj => obj.lab_sample_test_verified == 'true').length;
                this.$store.state.lab = r.data.lab;
                this.$store.state.lab_setting = r.data.lab_setting;
                this.lab_test_departments = r.data.lab_test_departments;
                this.lab_test_sub_departments = r.data.lab_test_sub_departments;

                const profiles = this.lab_sample_tests.filter(obj => obj.sub_test_count > 0)
                const profiles_without_sub_tests = this.lab_sample_tests.filter(obj => profiles.map(p => p.lab_sample_test_id).includes(obj.lab_sample_test_id)).filter(obj => !obj.lab_sample_sub_tests || obj.lab_sample_sub_tests.length == 0)

                if(this.$store.getters.lab_setting.display_profiles_without_sub_tests == 'hide' && profiles_without_sub_tests.length > 0) {
                    this.lab_sample_tests = this.lab_sample_tests.filter(obj => !profiles_without_sub_tests.map(p => p.lab_sample_test_id).includes(obj.lab_sample_test_id))
                }
                
                this.createBarcodeCanvas()
                if(this.$store.getters.lab_setting.patient_info_style_patient_report == 'modern_style') {
                    this.createQR()
                }
                
                this.lab_sample_tests = r.data.lab_sample_tests.filter(obj => obj.lab_sample_test_verified == 'true' && obj.lab_sample_test_print == 'true');
                const test_ids = this.lab_sample_tests.map(obj => {
                    return obj.lab_sample_test_id
                })

                if(Array.from(new Set(test_ids)).length > 0) {
                    await normal_range_request.get_list_lab_test_normal_ranges_by_column('lab_sample_test_id', Array.from(new Set(test_ids))).then(r => {
                        if(r.data) {
                            this.normal_ranges = r.data.rows;
                        }
                    })
                }
                
                this.sub_test_normal_ranges = r.data.sub_test_normal_ranges
                this.maxVerified = this.lab_sample_tests.filter(obj => obj.lab_sample_test_verified_time).map(obj => Number(obj.lab_sample_test_verified_time)).sort((a, b) => a-b).reverse()[0]
                if(this.$store.state.display_chart_in_print_sample) {
                    await axios.post(`lab/reports/testChartByPatient`, {
                        patient_id: this.patient_info.patient_id,
                        lab_id: this.lab_sample.lab_id,
                        lab_sample_date: new Date(this.lab_sample.lab_sample_date).toISOString().split('T')[0],
                        test_ids: this.lab_sample_tests.filter(t => this.$store.getters.lab_setting.test_chart_column == 'hide' || t.lab_sample_test_chart == 'true').map(obj => obj.lab_test_id).join(',')
                    }).then(r => {
                        const tests = r.data.tests.filter(obj => obj.results.length > 1);
                        for (let i = 0; i < tests.length; i++) {
                            let test = tests[i];
                            let option = {
                                chart_id: test.lab_test_id,
                                title: {
                                    text: this.$store.state.lab_setting.show_charts_type_sample_print == 'chart_end_of_report' || this.$store.state.lab_setting.show_charts_type_sample_print == 'chart_with_test' ? test.lab_test_name + ' (' + test.lab_test_unit_name + ')' : '',
                                    style: {
                                        fontSize: '10.1pt',
                                        fontWeight: 'bold'
                                    },
                                    offsetY: this.$store.state.lab_setting.show_charts_type_sample_print == 'chart_end_of_report' || this.$store.state.lab_setting.show_charts_type_sample_print == 'chart_with_test' ? 13 : 0,
                                    offsetX: this.$store.state.lab_setting.show_charts_type_sample_print == 'chart_end_of_report' || this.$store.state.lab_setting.show_charts_type_sample_print == 'chart_with_test' ? 7 : 0,
                                },
                                series: [{
                                    name: test.lab_test_name,
                                    data: test.results.map(obj => Number(obj.lab_sample_test_result)),
                                    style: {
                                        fontSize: 11,
                                        
                                    }
                                }],
                                chart: {
                                    height: this.hasStamp() ? this.$store.getters.lab_setting.sample_chart_height : this.$store.getters.lab_setting.sample_chart_height,
                                    width: '690px',
                                    type: 'area',
                                    fontFamily: 'Calibri',
                                    fontSize: '15pt',
                                    toolbar: {
                                        show: false
                                    },
                                    animations: {
                                        enabled: false,
                                    },
                                    
                                },
                                grid: {
                                    padding: {
                                        left: 20,
                                        right: 10
                                    }
                                },
                                markers: {
                                    size: 2
                                },
                                colors: [this.$store.getters.lab.print_title_background_color],
                                dataLabels: {
                                    enabled: true,
                                    style: {
                                        fontSize: 11
                                    },
                                    background: {
                                        foreColor: this.$store.getters.lab.print_title_text_color,
                                    },
                                },
                                stroke: {
                                    curve: 'smooth',
                                    width: 2
                                },
                                xaxis: {
                                    type: 'date',
                                    categories: test.results.map(obj => new Date(obj.lab_sample_date).toISOString().split('T')[0]),
                                    labels: {
                                        rotate: -45,
                                        rotateAlways: true,
                                        formatter: function (val) {
                                            return moment(val).format('DD-MM-YYYY');
                                        },
                                    },
                                    style: {
                                        fontSize: '10.1pt',
                                        fontWeight: 'bold'
                                    }
                                },
                                tooltip: {
                                    x: {
                                        format: 'yy-MM-dd'
                                    },
                                },
                            }
                            this.options.push(option)
                        }
                        setTimeout(() => {

                            for(let i = 0; i < this.options.length; i++) {
                                let option = this.options[i];
                                let chart = new ApexCharts(document.querySelector(`#chart${option.chart_id}`), option);
                                chart.render();
                            }
                        }, 350)
                    })
                }
                
            })
            
        },
        calculate_difference_in_days(from, to) {
            var start = moment(new Date(from), "YYYY-MM-DD");
            var end = moment(new Date(to), "YYYY-MM-DD");
            return moment.duration(start.diff(end)).asDays() * -1;
        },
        calculate_test_result(test) {
            let obj = {
                color: test.lab_sample_test_result_color || '#262626',
                normal_range_note: ''
            }
            
            const normal_ranges = this.normal_ranges.filter(obj => obj.lab_test_id == test.lab_test_id);
            var one_normal_range = null
            if(test.lab_test_filter == 'none') {
                one_normal_range = normal_ranges[0];
            } else if (test.lab_test_filter == 'gender') {
                one_normal_range = normal_ranges.find(obj => obj.lab_test_normal_range_gender == this.patient_info.gender_name_en.toLowerCase())
            } else if (test.lab_test_filter == 'age') {
                const {years, months, days} = this.getAge(this.patient_info.patient_birth_date)
                const ageWithDays = (years * 365) + (months * 30) + days

                const sortedNormalRanges = []

                sortedNormalRanges.push(...normal_ranges.filter(f => !f.lab_test_normal_range_min_age && f.lab_test_normal_range_max_age))
                sortedNormalRanges.push(...normal_ranges.filter(f => f.lab_test_normal_range_min_age && f.lab_test_normal_range_max_age).sort((a, b) => a.lab_test_normal_range_min_age - b.lab_test_normal_range_min_age))
                sortedNormalRanges.push(...normal_ranges.filter(f => f.lab_test_normal_range_min_age && !f.lab_test_normal_range_max_age))

                for(let i = 0; i < sortedNormalRanges.length; i++) {
                    const obj = sortedNormalRanges[i]
                    const min_age = obj.lab_test_normal_range_min_age
                    const max_age = obj.lab_test_normal_range_max_age

                    if(obj.lab_test_normal_range_max_age || obj.lab_test_normal_range_max_age == 0) {
                        if(ageWithDays <= max_age) {
                            one_normal_range = obj
                        }
                    } else if(obj.lab_test_normal_range_min_age || obj.lab_test_normal_range_min_age == 0) {
                        if(ageWithDays >= min_age) {
                            one_normal_range = obj
                        }
                    }

                    if(one_normal_range) {
                        break
                    }
                }

            } else if(test.lab_test_filter == 'age_gender') {
                const {years, months, days} = this.getAge(this.patient_info.patient_birth_date)
                const ageWithDays = (years * 365) + (months * 30) + days

                let sortedNormalRanges = []

                sortedNormalRanges.push(...normal_ranges.filter(f => !f.lab_test_normal_range_min_age && f.lab_test_normal_range_max_age))
                sortedNormalRanges.push(...normal_ranges.filter(f => f.lab_test_normal_range_min_age && f.lab_test_normal_range_max_age).sort((a, b) => a.lab_test_normal_range_min_age - b.lab_test_normal_range_min_age))
                sortedNormalRanges.push(...normal_ranges.filter(f => f.lab_test_normal_range_min_age && !f.lab_test_normal_range_max_age))

                sortedNormalRanges = sortedNormalRanges.filter(f => this.patient_info.gender_name_en.toLowerCase() == f.lab_test_normal_range_gender)

                for(let i = 0; i < sortedNormalRanges.length; i++) {
                    const obj = sortedNormalRanges[i]
                    const min_age = obj.lab_test_normal_range_min_age
                    const max_age = obj.lab_test_normal_range_max_age

                    if(obj.lab_test_normal_range_max_age || obj.lab_test_normal_range_max_age == 0) {
                        if(ageWithDays <= max_age) {
                            one_normal_range = obj
                        }
                    } else if(obj.lab_test_normal_range_min_age || obj.lab_test_normal_range_min_age == 0) {
                        if(ageWithDays >= min_age) {
                            one_normal_range = obj
                        }
                    }

                    if(one_normal_range) {
                        break
                    }
                }
                
            }

            if(one_normal_range) {
                obj.normal_range_note = one_normal_range.lab_test_normal_range_note

                if(test.lab_sample_test_result && this.extractNumberFromString(test.lab_sample_test_result) >= one_normal_range.lab_test_normal_range_min && this.extractNumberFromString(test.lab_sample_test_result) <= one_normal_range.lab_test_normal_range_max) {
                    obj.color = test.lab_sample_test_result_color || one_normal_range.lab_test_normal_range_color_normal || '#262626'
                } else if(test.lab_sample_test_result && this.extractNumberFromString(test.lab_sample_test_result) < one_normal_range.lab_test_normal_range_min) {
                    obj.color = test.lab_sample_test_result_color || one_normal_range.lab_test_normal_range_color_before_min || '#262626'
                } else if(test.lab_sample_test_result && this.extractNumberFromString(test.lab_sample_test_result) > one_normal_range.lab_test_normal_range_max) {
                    obj.color = test.lab_sample_test_result_color || one_normal_range.lab_test_normal_range_color_after_max || '#262626'
                }
            }

            return obj
        },
        calculate_sub_test_result(sub_test) {
            let obj = {
                color: sub_test.lab_sample_sub_test_result_color || '#262626',
                normal_range_note: ''
            }
            // normal-range-removed
            const normal_ranges = this.sub_test_normal_ranges.filter(obj => obj.lab_sub_test_id == sub_test.lab_sub_test_id);
            var one_normal_range = null
            if(sub_test.lab_sub_test_filter == 'none') {
                one_normal_range = normal_ranges[0];
            } else if (sub_test.lab_sub_test_filter == 'gender' ) {
                one_normal_range = normal_ranges.find(obj => obj.lab_sub_test_normal_range_gender == this.patient_info.gender_name_en.toLowerCase())
            } else if(sub_test.lab_sub_test_filter == 'age') {
                const {years, months, days} = this.getAge(this.patient_info.patient_birth_date)
                
                const ageWithDays = (years * 365) + (months * 30) + days

                const sortedNormalRanges = []

                sortedNormalRanges.push(...normal_ranges.filter(f => !f.lab_sub_test_normal_range_min_age && f.lab_sub_test_normal_range_max_age))
                sortedNormalRanges.push(...normal_ranges.filter(f => f.lab_sub_test_normal_range_min_age && f.lab_sub_test_normal_range_max_age).sort((a, b) => a.lab_sub_test_normal_range_min_age - b.lab_sub_test_normal_range_min_age))
                sortedNormalRanges.push(...normal_ranges.filter(f => f.lab_sub_test_normal_range_min_age && !f.lab_sub_test_normal_range_max_age))

                for(let i = 0; i < sortedNormalRanges.length; i++) {
                    const obj = sortedNormalRanges[i]
                    const min_age = obj.lab_sub_test_normal_range_min_age
                    const max_age = obj.lab_sub_test_normal_range_max_age

                    if(obj.lab_sub_test_normal_range_max_age || obj.lab_sub_test_normal_range_max_age == 0) {
                        if(ageWithDays <= max_age) {
                            one_normal_range = obj
                        }
                    } else if(obj.lab_sub_test_normal_range_min_age || obj.lab_sub_test_normal_range_min_age == 0) {
                        if(ageWithDays >= min_age) {
                            one_normal_range = obj
                        }
                    }

                    if(one_normal_range) {
                        break
                    }
                }

            } else if(sub_test.lab_sub_test_filter == 'age_gender') {
                const {years, months, days} = this.getAge(this.patient_info.patient_birth_date)
                
                const ageWithDays = (years * 365) + (months * 30) + days

                let sortedNormalRanges = []

                sortedNormalRanges.push(...normal_ranges.filter(f => !f.lab_sub_test_normal_range_min_age && f.lab_sub_test_normal_range_max_age))
                sortedNormalRanges.push(...normal_ranges.filter(f => f.lab_sub_test_normal_range_min_age && f.lab_sub_test_normal_range_max_age).sort((a, b) => a.lab_sub_test_normal_range_min_age - b.lab_sub_test_normal_range_min_age))
                sortedNormalRanges.push(...normal_ranges.filter(f => f.lab_sub_test_normal_range_min_age && !f.lab_sub_test_normal_range_max_age))

                sortedNormalRanges = sortedNormalRanges.filter(f => this.patient_info.gender_name_en.toLowerCase() == f.lab_sub_test_normal_range_gender)

                for(let i = 0; i < sortedNormalRanges.length; i++) {
                    const obj = sortedNormalRanges[i]
                    const min_age = obj.lab_sub_test_normal_range_min_age
                    const max_age = obj.lab_sub_test_normal_range_max_age

                    if(obj.lab_sub_test_normal_range_max_age || obj.lab_sub_test_normal_range_max_age == 0) {
                        if(ageWithDays <= max_age) {
                            one_normal_range = obj
                        }
                    } else if(obj.lab_sub_test_normal_range_min_age || obj.lab_sub_test_normal_range_min_age == 0) {
                        if(ageWithDays >= min_age) {
                            one_normal_range = obj
                        }
                    }

                    if(one_normal_range) {
                        break
                    }
                }
            }

            if(one_normal_range) {
                obj.normal_range_note = one_normal_range.lab_sub_test_normal_range_note
                
                if(isNaN(sub_test.lab_sample_sub_test_result) || this.isEmpty(sub_test.lab_sample_sub_test_result)) {
                    obj.color = sub_test.lab_sample_sub_test_result_color || '#262626'
                }  else if(parseFloat(sub_test.lab_sample_sub_test_result) >= parseFloat(one_normal_range.lab_sub_test_normal_range_min) && parseFloat(sub_test.lab_sample_sub_test_result) <= parseFloat(one_normal_range.lab_sub_test_normal_range_max)) {
                    obj.color = sub_test.lab_sample_sub_test_result_color || one_normal_range.lab_sub_test_normal_range_color_normal || '#262626'
                } else if(parseFloat(sub_test.lab_sample_sub_test_result) < parseFloat(one_normal_range.lab_sub_test_normal_range_min)) {
                    obj.color = sub_test.lab_sample_sub_test_result_color || one_normal_range.lab_sub_test_normal_range_color_before_min || '#262626'
                } else if(parseFloat(sub_test.lab_sample_sub_test_result) > parseFloat(one_normal_range.lab_sub_test_normal_range_max)) {
                    obj.color = sub_test.lab_sample_sub_test_result_color || one_normal_range.lab_sub_test_normal_range_color_after_max || '#262626'
                }
            }

            return obj
        },
        calculate_age(value) {
            const obj = {};
            if(Math.floor(value / 365) >= 1) {
                obj.value = Number((value / 365).toFixed(2))
                obj.text =  'Years'
            } else if(Math.floor(value / 30) >= 1) {
                obj.value = Number((value / 30).toFixed(2))
                obj.text = 'Months'
            } else {
                obj.value = value
                obj.text = 'Days'
            }
            return obj
        },
        getAge(dateString) {
            const date = moment(dateString, 'YYYY-MM-DD')

            const years = moment(this.lab_sample.lab_sample_date).diff(date, 'years')
            const months = moment(this.lab_sample.lab_sample_date).diff(date.add(years, 'years'), 'months', false)
            const days = moment(this.lab_sample.lab_sample_date).diff(date.add(months, 'months'), 'days', false)

            // const years = moment(this.lab_sample.lab_sample_date).get('year') - date.get('year')
            // const months = moment(this.lab_sample.lab_sample_date).get('month') - date.get('month')
            // const days = moment(this.lab_sample.lab_sample_date).get('date') - date.get('date')

            return { years, days, months }
        },
        printReport() {
            setTimeout(() => {
                window.print()
                if(!this.sample_id) {
                    window.close();
                }
                this.$store.state.show_navbar = true;
                if(darkTheme) {
                    this.$vuetify.theme.dark = true;
                }
            }, 250)
        },
        print() {
            this.is_print = true
            window.print()
            this.is_print = false
        },
        getSuitableRange(sub_test){
            if(sub_test.lab_sub_test_filter == 'gender'){
                const one_normal_range = (this.sub_test_normal_ranges.filter(obj => obj.lab_sample_sub_test_id == sub_test.lab_sample_sub_test_id).find(obj => (obj.lab_sub_test_normal_range_gender).toLowerCase() == (this.patient_info.gender_name_en).toLowerCase()) || {lab_sub_test_normal_range_min: '', lab_sub_test_normal_range_max: ''})
                return '(' + one_normal_range.lab_sub_test_normal_range_min + ' - ' + one_normal_range.lab_sub_test_normal_range_max + ')'
            } else {
                return  sub_test.lab_sample_sub_test_reference_range_note || this.calculate_sub_test_result(sub_test).normal_range_note 
            }
        },
        createBarcodeCanvas() {
            let canvas = document.createElement('canvas');
            bwipjs.toCanvas(canvas, {
                bcid: 'code128',
                text: this.patient_info.patient_id.toString(),
                scale: 1,          
                height: 9.9,
                includetext: false,
                textxalign:'center',
            });
            let elements = document.getElementsByClassName('canvas-patient-id')
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                element.setAttribute('src', canvas.toDataURL('image/png'))
            }

            let canvas2 = document.createElement('canvas');
            bwipjs.toCanvas(canvas2, {
                bcid:        'code128',
                text:        this.lab_sample.lab_sample_code.toString(),
                scale:       2,
                height:      9.9,
                includetext: false,
                textxalign:  'center',
                textyalign: 'above'
            });
            let elements_sample_code = document.getElementsByClassName('canvas-sample-code')
            for (let i = 0; i < elements_sample_code.length; i++) {
                const element = elements_sample_code[i];
                element.setAttribute('src', canvas2.toDataURL('image/png'))
            }
        },
        sortSubTests(subTests) {
            const copySubTests = [...subTests];
            return copySubTests.sort((a, b) => a.lab_sub_test_index - b.lab_sub_test_index)
        },
        hasStamp() {
            return this.$store.getters.lab.lab_sample_stamp || this.$store.getters.lab.lab_sample_stamp_start || this.$store.getters.lab.lab_sample_stamp_end
        },
        getBorder(type, showTop = null) {
            const setting = this.$store.getters.lab_setting.show_sample_data_border

            if(showTop) {
                return 'border-top: 1px solid #8F906A !important;'
            }

            if(type == 'rows') {
                if(setting == 'all_column_row') {
                    return 'border: 1px solid #8F906A !important;'
                } else if(setting == 'all_row') {
                    return 'border-top: 1px solid #8F906A !important; border-bottom: 1px solid #8F906A !important; border-left: none !important; border-right: none !important;'
                } else {
                    return 'border: none !important;'
                }
            } else if (type == 'profiles') {
                if(setting == 'profiles_only_column_row' || setting == 'all_column_row') {
                    return 'border: 1px solid #8F906A !important;'
                } else if(setting == 'profiles_only_row' || setting == 'all_row') {
                    return 'border-top: 1px solid #8F906A !important; border-bottom: 1px solid #8F906A !important; border-left: none !important; border-right: none !important;'
                } else {
                    return 'border: none !important;'
                }
            } else {
                return 'border: none !important;'
            } 
        },
        hasChartWithTest(lab_test_id) {
            return ['chart_with_test', 'chart_with_test_no_title'].includes(this.$store.state.lab_setting.show_charts_type_sample_print) && this.options.find(o => o.chart_id == lab_test_id)
        },
        createQR() {
            var img = document.getElementById('img_qr')
            QRCode.toDataURL('https://labresult.xeraclinic.com/lab_samples/' + this.lab_sample.lab_sample_uuid, {
                margin: 0
            }, function (error, url) {
                if (error) console.error(error)
                img.src = url
            })
        },
    },
}
</script>

<style scoped>
@font-face {
    font-family: 'xc-noto-sans';
    src: url('../../assets/Fonts/NotoSansArabic-SemiCondensed.ttf');
}
* {
    font-family: 'Calibri' !important;
}

@page {
    padding: 0 !important;
    margin: 0 !important;
    height: 29.7cm !important;
    width: 21cm !important;
    size: A4;
    background-color: white !important;
}

#app {
  background-color: rgb(255, 255, 255) !important;
  padding: 0 !important;
  margin: 0 !important;
}

.v-main {
    padding: 0 !important;
    margin: 0 !important;
}

#table td{
   
    /* border: 1px solid #8F906A; */
    border-top: none;
    border-width: 0.5px;
    font-size: 14px !important;
    font-weight: normal;
    color: #262626;
}
#table th{
    font-size: 12pt !important;
    font-weight: bold;
    color: #262626 !important;
}
p{
    font-size: 12pt !important;
    font-family: 'Calibri' !important;
}

.header {
  position: fixed;
  top: 0;
  height: 50px;
}

.footer {
    position: fixed;
    bottom: 0;
}

.main-div-print {
    width: 100% !important;
}


@media print {
    #table {
        margin-top: -0.22cm;
    }

    .header {
        position: fixed;
        top: 0;
        height: 50px;
    }

    .main-div-print {
        width: 21cm !important;
    }

    
    @page {
        padding: 0 !important;
        margin: 0 !important;
    }

    body {
        padding: 0 !important;
        margin: 0 !important;
        
    }    
}
</style>